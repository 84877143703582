// Generic imports
import React, { useState } from "react"
import { toast } from "react-toastify"
import styled from "styled-components"

// Other components imports
import { AccountStyles } from "components/Account/index"
import Button from "components/Button"
import Image from "components/image"
import Input from "components/Reusable/Input"
import { useFormik } from "formik"

// Redux imports
import * as authApi from "../../../../services/auth"

// Static imports

// Utils imports
import { handleAJAX } from "services/utils"

const StyledConfirmReset = styled.div`
  label {
    max-width: 329px;
    width: 90%;
    margin: auto;
    text-align: left;
  }
`

export default function ConfirmResetComponent(props) {
  const { uid, token } = props
  const [loading, setLoading] = useState(false)

  const formik = useFormik({
    initialValues: {
      new_password1: "",
      new_password2: "",
    },
    onSubmit: async values => {
      setLoading(true)
      if (values.new_password1 !== values.new_password2) {
        toast.error("Password do not match")
      } else {
        await handleAJAX(async () => {
          await authApi.confirmForgetPassword(
            values.new_password1,
            values.new_password2,
            uid,
            token
          )
          toast.success("Password has been reset successfully!")
        })
      }
      setLoading(false)
    },
  })

  return (
    <AccountStyles>
      <StyledConfirmReset>
        <div className="login-register">
          <div className="taman-logo">
            <Image imgName="taman-siswa-logo-full.png" alt="TamanSiswa" />
          </div>
          <form onSubmit={formik.handleSubmit} className="form-login-register">
            <Input
              id="new_password1"
              name="new_password1"
              type="password"
              placeholder="Password"
              label="New Password"
              required
              onChange={formik.handleChange}
              value={formik.values.new_password1}
            />
            <Input
              id="new_password2"
              name="new_password2"
              type="password"
              placeholder="Password"
              label="Repeat Password"
              required
              onChange={formik.handleChange}
              value={formik.values.new_password2}
            />
            <Button type="submit" disabled={loading}>
              Reset Password
            </Button>
            {/* <div className="register-here">
              Don&apos;t have account yet? <Link to="/register">Create One</Link>
            </div> */}
          </form>
        </div>
      </StyledConfirmReset>
    </AccountStyles>
  )
}
