/* eslint-disable no-unused-vars */
// Generic imports
import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
// import { Link } from "gatsby"

// Other components imports
import Layout from "components/layout"
import ConfirmResetComponent from "components/Account/ForgetPassword/ConfirmReset"
import SEO from "components/seo"

// Redux imports
import * as sessionSelectors from "rdx/slices/session/selectors"
import * as sessionThunks from "rdx/slices/session/thunks"

// Static imports

// Utils imports
import { getParameterByName } from "services/utils"

const mapStateToProps = state => ({
  isLoggedIn: sessionSelectors.isLoggedIn(state),
})

const mapDispatchToProps = {
  checkMyself: sessionThunks.checkMyself,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const AccountResetPasswordPage = props => {
  const { isLoggedIn, checkMyself } = props
  const [status, setStatus] = useState(0)
  const [uid, setUid] = useState("")
  const [token, setToken] = useState("")

  useEffect(() => {
    const uid = getParameterByName("uid")
    setUid(uid)
    const token = getParameterByName("token")
    setToken(token)
  }, [])

  return (
    <Layout>
      <SEO title="Reset Password" />
      <ConfirmResetComponent uid={uid} token={token} />
    </Layout>
  )
}

export default withConnect(AccountResetPasswordPage)
